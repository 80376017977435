@font-face {
  font-family: "font-light";
  src: url("../fonts/Kanit-Light.ttf") format("truetype");
}

@font-face {
  font-family: "font-medium";
  src: url("../fonts/Kanit-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "font-regular";
  src: url("../fonts/Kanit-Regular.ttf") format("truetype");
}

:root {
  --color-primary: #1e4f7b;
  --font-primary: #135179;
}

body {
  font-family: "font-regular";
}

.hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
  margin-bottom: -5px;
}

.gridLayout {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.navbar-Theme {
  background: var(--color-primary);
}

.bg-custom-primary {
  background: var(--color-primary);
}

.btn-circle {
  width: 20px;
  height: 20px;
  padding: 0px 0px;
  border-radius: 10px;
  font-size: 13px;
}

.nav-link {
  color: #fff;
}

.nav-link.active {
  color: #fff;
}

a.nav-item {
  text-decoration: none;
}

#sidebar {
  width: 15rem;
}

.nav-label small {
  color: #dc9044;
}

.nav-link:hover,
.nav-link:focus {
  color: #dc9044;
}

.nav-item.active {
  background-color: #fff;
}

.nav-item.active .nav-link {
  color: var(--color-primary);
  background-color: #fff;
}

table {
  text-align: center;
}

.btn-outline-secondary {
  min-width: 130px;
  font-size: 18px;
  padding: 0.26em 0.75em;
}

.btn-success,
.btn-secondary {
  min-width: 130px;
  font-size: 18px;
  padding: 0.15em 0.75em;
}

.btn-primary {
  color: #fff;
  border-color: #fcda59;
  background: linear-gradient(60deg, #fcda59, #dc9044);
  min-width: 130px;
  font-size: 18px;
  padding: 0.15em 0.75em;
}

.nav-tabs .nav-link.active {
  background-color: white;
  border-bottom-color: white;
  color: #135179;
}

.nav-tabs .nav-link {
  background-color: #cfcfcf;
  color: #135179;
}

span.logoTitle {
  font-size: 40px;
  color: #dc9044;
  display: none;
}

.mini-sidebar img[alt="logoImg"] {
  display: none !important;
}

.mini-sidebar .logoTitle {
  display: block !important;
  text-align: center;
  width: 56px;
  text-align: center;
  margin-bottom: 0.2rem;
}

.mini-sidebar .nav-drop {
  display: none;
}

.form-label {
  font-size: 18px;
  color: var(--font-primary);
}

.page-header {
  font-size: 22px;
  color: var(--font-primary);
  font-weight: 600;
}

.page-header span {
  font-weight: 300;
}

.cursor-pointer {
  cursor: pointer;
}

.media-select-square {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #d9d9d9;
  cursor: pointer;
  color: var(--font-primary);
  border-radius: 10px;
  font-size: 18px;
  /* max-width: 250px; */
}

.media-select-squareImage {
  position: relative;
  width: 100%;
  object-fit: cover;
  padding: 0% !important;
  content: none !important;
  overflow: hidden;
  background-color: #d9d9d9;
  cursor: pointer;
  color: var(--font-primary);
  border-radius: 10px;
  font-size: 18px;
  /* max-width: 250px; */
}

.media-select-square:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.media-select-square .content {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.add-section-box {
  width: 100%;
  min-height: 100px;
  border: 3px dashed #d9d9d9;
  color: #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.add-section-box span {
  font-size: 48px;
}

.section-primary {
  color: var(--font-primary);
  font-size: 17px;
}

.section-config .form-label {
  font-size: 15px;
  margin-bottom: 0.25rem;
}

.form-check-input:checked {
  background-color: var(--color-primary);
}

.text-align-left {
  text-align: left;
}

.ql-container {
  min-height: 13rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.border-danger {
  border-color: red;
}

.table-rc {
  border-color: var(--color-primary);
}

.table-photo input {
  visibility: hidden;
}

.media-image-square {
  position: relative;
  width: 250px;
  height: 280px;
  overflow: hidden;
  background-color: #d9d9d9;
  cursor: pointer;
  color: var(--font-primary);
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-width: 250px; */
}

.industry-header {
  width: max-content;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 10;
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px -4px 3px rgba(223, 217, 217, 0.75);
}

.industry-header div {
  color: var(--color-primary);
  font-size: 20px;
  font-weight: lighter !important;
  cursor: pointer;
  padding: 5px 10px;
}

.industry-header-buttons-active {
  background-color: #aaa;
  /* color: white ; */
  border-radius: 10px;
}

.industry-header-flex {
  display: flex;
}

.Minus-Margine {
  margin-top: -5px;
}

.publish-card div {
  margin-top: 10px;
  display: flex;
}

.publish-card div label {
  margin-right: 10px;
  font-weight: 100 !important;
  font-size: 15px;
}

.custom-btn {
  background: green !important;
  color: #fff;
  min-width: 130px;
  font-size: 18px;
  padding: 0.15em 0.75em;
  border-color: green;
}

.add_section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-size: 25px;
  border: 3px dashed #d9d9d9;
  color: #4b5563;
  cursor: pointer;
  border-radius: 20px;
  text-transform: capitalize;
}

.add_section .add_icon {
  padding: 0 10px;
  font-size: 65px;
  margin-top: -5px;
}

.custom_css .rse-StyleEditor-root {
  min-height: 500px;
  border-radius: 10px;
}


/* Set dropdown font-families */
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Serif"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Serif"]::before {
  font-family: "Serif";
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Sans-serif	"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Sans-serif	"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Sans-serif	"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Sans-serif	"]::before {
  font-family: "Sans-serif	";
  content: "Sans-serif	";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Monospace"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Monospace"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Monospace"]::before {
  font-family: "Monospace";
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Cursive"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Cursive"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Cursive"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Cursive"]::before {
  font-family: "Cursive";
  content: "Cursive";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Fantasy"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Fantasy"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Fantasy"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Fantasy"]::before {
  font-family: "Fantasy";
  content: "Fantasy";
}

/* Set content font-families */
.ql-font-Serif {
  font-family: "Serif";
}

.ql-font-Sans-serif {
  font-family: "Sans-serif";
}

.ql-font-Monospace {
  font-family: "Monospace";
}

.ql-font-Cursive {
  font-family: "Cursive";
}

.ql-font-Fantasy {
  font-family: "Fantasy";
}

.imageSize {
  object-fit: contain;
}

button#pageDropDown {
  min-width: auto;
}

.iconSize {
  font-size: 1.3rem;
  margin-right: 1.3rem;
}

.sideLabel {
  width: 100%;
  display: inline-block;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}